import React from "react"
import classNames from "classnames"

export const Box = ({
  children,
  blue,
  white,
  noshadow,
  centered,
  height,
  hover,
  primary,
}) => (
  <div
    className={classNames("box", {
      "theme-box": blue,
      "theme-box-white": white,
      "is-shadowless": noshadow,
      "has-text-centered": centered,
      "h-100": height,
      "hvr-icon-pop": hover,
      "has-background-primary": primary,
    })}
  >
    {children}
  </div>
)
